<template>
  <div class="hello" style="width: 620px; margin: auto;">
    <h1>{{ msg }}</h1>
    <p style="padding-bottom: 15px;">{{ description }}</p>
    <!-- <form id="cta" @submit.prevent="submitEmail">
      <input v-model="email" />
      <button>Join</button>
    </form> -->
    <h3>Email:</h3> <a href="mailto:support@nabamedical.com">support@nabamedical.com</a>
    <h3>Phone:</h3> 703.810.7221
    <h3>Fax:</h3> 571.601.2803
    <h3>Address:</h3>
    <p>1751 Pinnacle Drive, Suite 600 <br />
      Tysons, VA 22102</p>
    <h3>Social Links</h3>
    <ul class="footer">
      <li v-for="link in links" :key="link.url"><a :href="link.url" target="_blank" rel="noopener">{{ link.name }}</a>
      </li>
    </ul>
    <h3>Terms & Policies</h3>
    <ul class="footer">
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vQWR6ZkjYpqKAHQIySvzhrrjJ_fkucC97BoGxUHP8qblalg_T0gfwSrTDSjsb7ZrWuPTdQb5mB88gz3/pub">Terms
          of Service</a></li>
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vRfhM10r3rxlv6N20XtguIlK47z1IZVdQTCIG5QlWkf_eG9aZsjxoJ9mXYZDW8L6riKJXuykVf8FsZd/pub">Privacy
          Policy</a></li>
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vS3v2dkfgRfO1bAB1LR-O0ZRDpBbF3osdx8bkOFSyXMTr0X30l3GfKEDcY2rrrXRAxekLsmpw4tMA-g/pub">Notice
          of Privacy Practices</a></li>
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vSLR-WPxpiuqX3KW8Il9USqPdDyPGUBgic2OSUkgrINg2qQth_6C-EtgyHP8U63-TcUJnnggxCkPu4E/pub">Patient
          Care Agreement</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    description: String
  },
  data() {
    return {
      email: 'Enter you email address',
      links: [
        {
          url: 'https://twitter.com/NabaMedical',
          name: 'Twitter'
        },
        {
          url: 'https://www.linkedin.com/company/nabamedical/',
          name: 'LinkedIn'
        },
        {
          url: 'https://www.facebook.com/NabaMedicalPC',
          name: 'Facebook'
        },
        {
          url: 'https://www.instagram.com/nabamedical/',
          name: 'Instagram'
        }
      ]
    }
  },
  methods: {
    submitEmail() {
      console.log(this.email);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul.footer>li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

form#cta>input {
  margin: 10px;
}
</style>
